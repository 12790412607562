import { Link, NavLink, Outlet, RouterProvider, ScrollRestoration, createBrowserRouter } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';
import './App.css';
import CleanHomePage from './pages/CleanHomePage';
import HomePage from './pages/HomePage';
import { isMobile } from './utils';
import { useEffect } from 'react';
import "./i18n/config";

interface GlobalConfig {
  DEBUG?: boolean;
  PORN_MODE?: boolean;
  BACKEND_ENDPOINT: string;
  TEST_CUSTOM_TOKEN?: string;
};

export const globalConfig = (window as any) as GlobalConfig;

export const getPornMode = () => {
  if (window.location.hostname == "video-swap.com") {
    return false;
  }
  if (globalConfig.PORN_MODE !== undefined) {
    return globalConfig.PORN_MODE
  }
  return true;
};

export const getSiteName = () => {
  return getPornMode() ? "PornSwap" : "VideoSwap";
};
const App = () => {
  return getPornMode() ? <HomePage /> : <CleanHomePage />;
}

export default App;
