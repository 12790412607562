import { FC } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const Center = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
`;

const Container = styled.div`
  flex-direction: column;
  color: white;
  width: 500px;
  margin: 25px 20px 20px 20px;
  display: flex;
  font-weight: 500;
  color: #D1D1D1;
`;

const TopContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const Video = styled.video`
  width: 100%;
  border-radius: 10px;
  border: 1px solid #424242;
`;

const FacesVideo = styled.video`
  width: 100%;
  border-radius: 10px;
  border: 1px solid #424242;
  margin-top: 15px;
`;

const TopText = styled.div`
  position: absolute;
  z-index: 2;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Logo = styled.div`
  font-weight: bold;
  color: #fea700;
  text-shadow: 0 0 15px rgba(0, 0, 0, 1);
  text-align: center;
`;

const TopLogo = styled.div`
  font-size: 45px;

  @media (max-width: 560px) {
    font-size: 30px;
  }
`;

const MiniLogo = styled.div`
  font-size: 30px;

  @media (max-width: 560px) {
    font-size: 20px;
  }
`;

const ConceptText = styled.div`
  color: #f5f5f5;
  font-size: 24px;
  font-weight: bold;
  margin-top: 5px;
  text-align: center;
  text-shadow: 1px 1px 3px #808080;

  @media (max-width: 560px) {
    font-size: 18px;
  }
`;

const CreateButton = styled.span`
  margin-top: 10px;
  border-radius: 5px;
  height: 44px;

  background-color: rgb(254, 167, 0);
  color: black;
  padding: 10px;

  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-weight: bold;
  color: black;

  &:hover {
    background-color: #f8be53;
  }
`;

const PornSiteIconList = styled.div`
  margin-top: 10px;
  display: flex;
  align-items: center;
`;

const PornSiteIconListCenter = styled.div`
  margin-top: 10px;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
`;

const PornSiteIcon = styled.img`
  height: ${x=> x.height};
`;

const StepImg = styled.img`
  width: calc(100% - 20px);
  margin: 10px;
`;

const StepImgContainer = styled.div`
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #1d1d1d;
  border-radius: 10px;
  border: 1px solid gray;
`;

const StepB = styled.span`
  font-weight: bold;
  color: #fea700;
`;

const StepSubtitle = styled.span`
  font-weight: bold;
  font-size: 15px;
  color: #C0C0C0;
`;

const StepTitle = styled.legend`
  font-weight: 700;
  font-size: 18px;
`;

const StepContainer = styled.fieldset`
  border-radius: 10px;
  background-color: #2b2b2b;
  border: 1px solid #424242;
  padding: 20px;
  margin-top: 20px;
`;

const SectionTitle = styled.div`
  margin-top: 20px;
  font-size: 30px;
  font-weight: bold;
  width: 100%;
  text-align: center;
  color: #fea700;

  @media (max-width: 560px) {
    font-size: 20px;
  }
`;

const SectionText = styled.div`
  font-size: 18px;
  width: 100%;
  margin-top: 10px;

  @media (max-width: 560px) {
    font-size: 16px;
  }
`;

const LinkButtonContainer = styled.div`
  margin-top: 20px;
`;

const LogoContainer = styled.div`
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 15px;
  border: 1px solid #fea700;
  padding: 5px 10px;

  @media (min-width: 560px) {
    padding: 5px 15px;
  }
`;

const PriceText = styled.div`
  width: 100%;
  text-align: center;
  font-size: 30px;
  margin-bottom: 10px;
`;

const Emphasis = styled.span`
  font-weight: bold;
  color: white;
`;

const AwardContainer = styled.div`
  width: 100%;
  text-align: center;
  margin-top: 10px;
  display: flex;
`;

const AwardImg = styled.img`
  max-width: 150px;
`;

const AwardText = styled.div`
  text-align: left;
  display: flex;
  align-items: center;
`;

const LinkButton: FC<{}> = (props) => {
  return <LinkButtonContainer>
    <Link to="/create">
      <CreateButton>
        Let's face swap video in 10 seconds!
      </CreateButton>
    </Link>
  </LinkButtonContainer>;
};

const CleanHomePage: FC<{}> = (props) => {
  return <><Center>
    <Container>
      <TopContainer>
        <Video src="/lp/card/top_faces.mp4" autoPlay loop muted playsInline/>
        <TopText>
          <LogoContainer>
            <Logo>
              <TopLogo>
                Face Swap Video
              </TopLogo>
              <MiniLogo>
                streaming in 10 seconds
              </MiniLogo>
            </Logo>
            <ConceptText>
              Import video from any URL😎
            </ConceptText>
          </LogoContainer>
        </TopText>
      </TopContainer>

      <SectionTitle>
        Revolutionize your loved😘 video <br /> with face swap ⚡️
      </SectionTitle>
      <SectionText>
        Just by entering <Emphasis>any video URL</Emphasis>(AI recognizes a video from video site URL) and a face photo, 
        it can swap the face and stream the video in just 10 seconds.
        You can create video with your favorite face and best situation.
      </SectionText>

      <LinkButton />
      <FacesVideo src="/lp/ex/faces.mp4" autoPlay loop muted playsInline/>

      <SectionTitle>
        Application of state-of-the-art <br/> AI technology
      </SectionTitle>
      <SectionText>
      We use the most accurate AI model in the world, developed by researchers at Google and Meta, which has won several international AI competitions.
        Experience the world's best technology!
      </SectionText>

      <AwardContainer>
        <AwardText>
          2023.06: Rank 1st of ECTV 2023 WCAP Challenge. <br />
          2022.11: Rank 1st of NEST-FRXT 1:1. <br />
          2020.09: Rank 1st of WILDER Face Detection Challenge 2020.
        </AwardText>
        <AwardImg src="/lp/awards.png" />
      </AwardContainer>

      <SectionText>
        On social media, it spread with overwhelming momentum, garnering billions of impressions. 
        It has completely transformed the global video lifestyle.
      </SectionText>
      <LinkButton />

      <SectionTitle>
        Thank you to 1 million users 🚀
      </SectionTitle>

      <SectionText>
        So far, 1 million users have swapped faces. 
        Moving forward, we'll continue to deliver the ultimate excitement through the video streaming revolution.
      </SectionText>

      <SectionTitle>
        Only three steps 😃
      </SectionTitle>

      <StepContainer>
        <StepTitle>STEP1: <StepSubtitle>SET VIDEO URL</StepSubtitle></StepTitle>
        Enter <StepB>any video URL</StepB> of the video site.
      </StepContainer>

      <StepContainer>
        <StepTitle>STEP2: <StepSubtitle>UPLOAD FACE IMAGE</StepSubtitle></StepTitle>
        Upload <StepB>your favorite face</StepB> you want to replace the original face.
      </StepContainer>

      <StepContainer>
        <StepTitle>STEP3: <StepSubtitle>LET'S GENERATE</StepSubtitle></StepTitle>
        Just wait <StepB>10 seconds</StepB> to stream the generated video!
      </StepContainer>

      <LinkButton />
      <br />
      <br />
    </Container>
  </Center>
  </>
};

export default CleanHomePage;
